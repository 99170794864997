@import url('./fonts/fonts.scss');

@tailwind base;
@tailwind components;
@tailwind utilities;

// * {
//   $scrollRound : 1px;
//   $scrollWidth : 6px;
//   $scrollHeight: 10px;
//   $scrollBG_track : #6e829c;
//   $scrollBG_thumb : #061d31;
//   $scrollColor : #26384c #452d6b;

//   box-sizing: border-box;
//   scrollbar-width: $scrollWidth;
//   scrollbar-color: $scrollColor;

//   &::-webkit-scrollbar {
//     width: $scrollWidth;
//     height: $scrollHeight;
//   }

//   &::-webkit-scrollbar-track {
//     background: $scrollBG_track;
//   }

//   &::-webkit-scrollbar-thumb {
//     border-radius: $scrollRound;
//     background-color: $scrollBG_thumb;
//   }
// }

html,
body {
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 15px;
  font-family: ui-sans-serif, system-ui;
}